// 引入工具函数
import { http, httpimg } from '../index';

// 国家列表
export const listCounty = params => http.get('/ohealth/api/v1/system/common/listCounty', params)

// 省列表
export const listRegion = params => http.get('/ohealth/api/v1/system/common/listRegion', params)

// 市列表
export const subListApi = params => http.post('/ohealth/api/v1/system/common/subList', params)

// 民族
export const nationList = params => http.get('/ohealth/api/v1/system/dictdata/type/nation_type', params)

/* 下载隔离解除告知书 */
export const downloadRelieveSignBook = params => httpimg.get('/ohealth/api/v1/isolation/reg/downloadRelieveSignBook', params)

// 未入住人员列表 列表
export const regList = params => http.get('/ohealth/api/v1/isolation/reg/list', params)

// 未入住人员列表 - 发送入住告知书
export const sendEntrySign = params => http.get('/ohealth/api/v1/isolation/reg/sendEntrySign', params)

// 未入住人员列表 - 入住告知书 确认签署
export const confirmEntrySign = params => http.get('/ohealth/api/v1/isolation/reg/confirmEntrySign', params)

/* 未入住人员列表 打印报告书 */
export const downloadEntryBook = params => httpimg.get('/ohealth/api/v1/isolation/reg/downloadEntryBook', params)

// 未入住人员列表 - 导出
export const exportRegListApi = params => httpimg.post('/ohealth/api/v1/isolation/reg/exportRegList', params)


// 告知书管理
export const noticeList = params => http.get('/ohealth/api/v1/isolation/reg/noticelist', params)

// 新增登记
export const isolationRegister = params => http.post('/ohealth/api/v1/isolation/reg/save', params)

// 删除居民信息
export const regDelete = params => http.delete('/ohealth/api/v1/isolation/reg/delete', params)


// 查询安排隔离信息
export const getArrangeInfo = params => http.get('/ohealth/api/v1/isolation/reg/getArrangeInfo', params)

// 安排隔离入住
export const arrangeEntryLive = params => http.get('/ohealth/api/v1/isolation/reg/arrangeEntryLive', params)

// 隔离登记列表
export const residentlistt = params => http.get('/ohealth/api/v1/isolation/resident/list', params)

// 已入住人员列表（jzy）
export const alreadyChenckIn = params => http.get('/ohealth/api/v1/isolation/todolistcovid/regLiveList', params)

// 发送解除隔离告知书
export const sendRelieveSignBook = params => http.get('/ohealth/api/v1/isolation/reg/sendRelieveSignBook', params)

// 根据 1集中隔离  2居家隔离 提示语
export const reliveControlTips = params => http.get('/ohealth/api/v1/epidemicControl/homereg/reliveControlTips', params)


// 已入住人员列表-解除隔离
export const relieveIsolate = params => http.post('/ohealth/api/v1/isolation/resident/relieveIsolate', params)

// 已入住人员列表-预解除隔离
export const applyRelieveIsolateApi = params => http.post('/ohealth/api/v1/isolation/resident/applyRelieveIsolate', params)

// 已入住人员列表-居民资料  录入核酸
export const collectDetect = params => http.post('/ohealth/api/v1/isolation/resident/collectDetect', params)

// 已入住人员列表-居民资料  删除核酸记录
export const collectDetectTask = params => http.delete('/ohealth/api/v1/isolation/todolistcovid/deleteTask', params)

// 已入住人员列表-居民资料  录入抗体
export const collectAntibody = params => http.post('/ohealth/api/v1/isolation/resident/collectAntibody', params)

// 已入住人员列表-居民资料 删除抗体记录
export const collectAntibodyTask = params => http.delete('/ohealth/api/v1/isolation/todolistantibody/deleteTask', params)

// 已入住人员列表-居民资料  录入体温
export const collectTempAndSymptom = params => http.post('/ohealth/api/v1/isolation/resident/collectTempAndSymptom', params)

// 已入住人员列表-居民资料
export const queryInfo = params => http.post('/ohealth/api/v1/isolation/resident/queryInfo', params)

// 已入住人员列表-居民资料  核酸测量-新增监测
export const insertNucleateTask = params => http.post('/ohealth/api/v1/isolation/todolistcovid/insertTask', params)

// 已入住人员列表-居民资料  抗体测量-新增监测
export const insertAntibodyTask = params => http.post('/ohealth/api/v1/isolation/todolistantibody/insertTask', params)

// 居民详情-根据regId查询隔离居民详情
export const getIsolateDetailByRegId = params => http.post('/ohealth/api/v1/isolation/resident/getIsolateDetailByRegId', params)

// 街道详情-根据Id查询隔离街道详情
export const getStreetRegInfo = params => http.get('/ohealth/api/v1/epidemicControl/streetreg/info/' + params.id, params)

// 换房间
export const changeUserRoomApi = params => http.post('/ohealth/api/v1/isolation/reg/changeUserRoom', params)

// 查询核酸检测采样方式列表
export const samplingModeList = params => http.get('/ohealth/api/v1/system/dictdata/type/sampling_mode', params)

// 调用打印条码
export const printCovidBarCode = params => http.get('/ohealth/api/v1/isolation/resident/printCovidBarCode', params)

// 调用批量打印条码
export const batchPrintCovidBarCode = params => http.post('/ohealth/api/v1/isolation/resident/batchPrintCovidBarCode', params)

// 查询隔离检测记录-体温测量
export const tempList = params => http.get('/ohealth/api/v1/isolation/todolisttemp/tempList', params)

// 导出隔离检测记录-体温测量
export const exportTempList = params => httpimg.post('/ohealth/api/v1/isolation/todolisttemp/exportTempList', params)

// 查询隔离检测记录-核酸测量
export const covidList = params => http.get('/ohealth/api/v1/isolation/todolistcovid/covidList', params)
// 同步检测
export const pullCovidResult = params => http.get('/ohealth/api/v1/isolation/resident/pullCovidResult', params)

// 导出隔离检测记录-核酸测量
export const exportCovidList = params => httpimg.post('/ohealth/api/v1/isolation/todolistcovid/exportCovidList', params)

// 保存告知书管理上传文件
export const saveNotice = params => http.post('/ohealth/api/v1/isolation/reg/saveNotice', params)

// 隔离检测记录-抗体检测
export const findAntibodyPage = params => http.get('/ohealth/api/v1/isolation/todolistantibody/findAntibodyPage', params)

// 隔离检测记录-存入检测时间
export const updateCollectTime = params => http.put('/ohealth/api/v1/isolation/todolistantibody/updateCollectTime', params)

// 导出隔离检测记录-抗体检测
export const exportAntibodyList = params => httpimg.post('/ohealth/api/v1/isolation/todolistantibody/exportAntibodyList', params)


// 人群分类数据 导出总表
export const exportTotal = params => httpimg.post('/ohealth/api/v1/isolation/excelexport/exportTotal', params)
// 已入住-导出分表-人群分类
export const exportRegLiveCrowdTypeApi = params => httpimg.post('/ohealth/api/v1/isolation/excelexport/exportRegLiveCrowdType', params)
// 已入住人员列表 导出分表
export const exportRegLiveListApi = params => httpimg.post('/ohealth/api/v1/isolation/resident/exportRegLiveList', params)
// 未入住人员更换隔离点 
export const replaceIsolationPoint = params => http.post('/ohealth/api/v1/isolation/reg/replaceIsolationPoint', params)


