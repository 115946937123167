function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

export function imgUrlFilter(val, qiniuimg, localimg) {
  if (window.location.host.includes('admin.rih.medrd')) {
    return val
  }
  if (val) {
    return val.replace(qiniuimg, localimg)
  }
}

export function sexFilter(sex) {
  const typeMap = {
    '1': '男',
    '0': '女'
  }
  return typeMap[sex] || '未知'
}

export function subTime(time, cFormat) {
  if (arguments[0] === undefined || arguments[0] == null) {
    return null
  }
  if (cFormat === 'dateTime') {
    try {
      return parseTime(time, '{y}-{m}-{d} {h}:{i}:{s}')
    } catch (e) {
      return time
    }
  } else if (cFormat === 'time') {
    try {
      return time.substr(10)
    } catch (e) {
      return parseTime(time, '{h}:{i}:{s}')
    }
  } else if (cFormat === undefined || 'data') {
    try {
      return time.substr(0, 10)
    } catch (e) {
      return parseTime(time, '{y}-{m}-{d}')
    }
  }
}
export function parseJson(value, column) {
  value = JSON.parse(value)
  if (column === 'cost') return value.cost
  if (column === 'arrearage') return value.arrearage
}
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  if (arguments[0] === undefined || arguments[0] == null) {
    return null
  }
  if ((time + '').length === 10) {
    time = +time * 1000
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    date = new Date(parseInt(time))
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

/* 数字 格式化 */
export function nFormatter (num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

export function html2Text (val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

export function toThousandsLsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/* 转化为人名币  大写 */
export function smallToBIG(n) {
  var fraction = ['角', '分']
  var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  var unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  var head = n < 0 ? '欠' : ''
  n = Math.abs(n)

  var s = ''

  for (var i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }
  s = s || '整'
  n = Math.floor(n)

  for (let i = 0; i < unit[0].length && n > 0; i++) {
    var p = ''
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p
      n = Math.floor(n / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整')
}
// 将数字（整数）转为汉字，从零到一亿亿，需要小数的可自行截取小数点后面的数字直接替换对应arr1的读法就行了
export function convertToChinaNum (num) {
  let arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  let arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿']// 可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return '零'
  }
  let english = num.toString().split('')
  let result = ''
  for (let i = 0; i < english.length; i++) {
    let desi = english.length - 1 - i// 倒序排列设值
    result = arr2[i] + result
    let arr1Index = english[desi]
    result = arr1[arr1Index] + result
  }
  // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')
  // 合并中间多个零为一个零
  result = result.replace(/零+/g, '零')
  // 将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')
  // 将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿')
  // 移除末尾的零
  result = result.replace(/零+$/, '')
  // 将【零一十】换成【零十】
  // result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, '十')
  return result
}

export function publishStatusFilter (status) {
  const typeMap = {
    '0': '未发布',
    '1': '已上线',
    '2': '已下线'
  }
  return typeMap[status]
}

export function serviceTaskTypeFilter(val) {
  let type = {
    'visit': '随访',
    'survey': '问卷',
    'content': '宣教',
    'check': '检测',
    'assessment': '评估'
  }
  return type[val]
}

export function propagandaTypeFilter(val) {
  let type = {
    'text': '图文',
    'video': '视频',
    'link': '链接'
  }
  return type[val]
}

export function dataFromFilter(val) {
  if (!val) {
    return '华医康道'
  }
  let type = {
    'WEI_NING': '卫宁',
    'HYKD': '华医康道'
  }
  return type[val]
}


/**
 * 时间格式化
 * yyyy-MM-dd hh:mm:ss.S
 * wang haha
 */
export function dateFormat(date, fmt) {
  var _date = new Date(date);
  var o = {
    "M+": _date.getMonth() + 1, //月份
    "d+": _date.getDate(), //日
    "h+": _date.getHours(), //小时
    "m+": _date.getMinutes(), //分
    "s+": _date.getSeconds(), //秒
    "q+": Math.floor((_date.getMonth() + 3) / 3), //季度
    "S": _date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (_date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

//截取字符串
export function strSliceFilter(str, n1, n2) {
  let value = '';
  if (str && str !== '') {
    value = str.slice(n1, n2)
  }
  return value;
}

export function pageObjFilter (obj) {
  let {pageNum,pageSize,total} = obj;
  let pageObj = {pageNum,pageSize,total};
  pageObj.total = Number(pageObj.total);
  pageObj.pageNum = pageObj.pageNum + 1;
  return pageObj
}
export function ageFilter(data) { //根据生日算年龄
  if (data) {
    let myDate = new Date();
    let month = myDate.getMonth() + 1;
    let day = myDate.getDate();
    // console.log(day);
    // console.log(data.substring(5, 7));
    let age = myDate.getFullYear() - data.substring(0, 4) - 1;
    if (data.substring(5, 7) < month || data.substring(5, 7) === month && data.substring(8, 10) <= day || data.substring(9, 10) <= day) {
      age++;
    }
    // console.log(age);

    if (age < 0) {
      return ''
    }
    return age
  } else {
    return '';
  }
}

export function statusFilter(val) {
  const statusMap = {
    0: "warning",
    1: "danger",
    2: "success",
  };
  return statusMap[val];
}

export function pastDisease(data) {
  if (data) {
    let list = JSON.parse(data).value;
    let pastDiseaseValue = '';
    list.forEach(item => {
      pastDiseaseValue += item.name + ',';
    })
    return pastDiseaseValue.substring(0, pastDiseaseValue.length - 1);
  } else {
    return '';
  }
}

export function visitWay(val, dictionary) {
  let way = '';
  dictionary.visit_way.forEach(item => {
    if (item.value === val) {
      way = item.label;
    }
  });
  return way;
}

export function dictFilter(val, types) {
  let o = '未知'
  types.forEach(type => {
    if (type.key === val) o = type.value
  })
  return o
}


export function dictFilter2(val, types) {
  let o = '未知'
  types.forEach(type => {
    if (type.value === val) o = type.label
  })
  return o
}
