import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as filters from './filters' // 全局filter
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/permission' // 权限
import '@/prototype' // prototype
import commonFunction from '@/commonFunction' // 公共方法


import {
  httpimg,
  http,
  httpxu
} from './api/index';
import moment from 'moment';
import {
  VueJsonp
} from 'vue-jsonp'


Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(VueJsonp)
Vue.use(commonFunction)
Vue.prototype.$httpimg = httpimg;
Vue.prototype.$http = http;
Vue.prototype.$httpxu = httpxu;
Vue.prototype.$store = store;
Vue.prototype.$moment = moment;
moment.locale('zh-cn'); //需要汉化

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')