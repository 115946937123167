// 规则管理api
import {
  http,
  httpimg
} from '../../api/index'

// 人群分类字典
export const rqtype = params => http.get('/ohealth/api/v1/system/dictdata/type/covid_crowd_type', params)

// 查询规则-人群分类列表(启用)
export const getCrowdTypeList = params => http.get('/ohealth/api/v1/isolation/rulecrowd/getRuleCrowdList', params)

// 查询人群分类(所有)
export const getAllRuleCrowdList = params => http.get('/ohealth/api/v1/isolation/rulecrowd/getAllRuleCrowdList', params)

// 人群分类-列表
export const rulecrowdtist = params => http.get('/ohealth/api/v1/isolation/rulecrowd/list', params)

// 人群分类-详情
export const ruleCrowdInfo = params => http.get('/ohealth/api/v1/isolation/rulecrowd/info', params)

// 人群分类-新增保存
export const rulecrowdsave = params => http.post('/ohealth/api/v1/isolation/rulecrowd/save', params)

// 人群分类-修改
export const rulecrowdupdate = params => http.put('/ohealth/api/v1/isolation/rulecrowd/update', params)

// 人群分类-修改人群状态
export const rulecrowdupdateStatus = params => http.put('/ohealth/api/v1/isolation/rulecrowd/updateStatus', params)

// 隔离告知书类型
export const noticeBookType = params => http.get('/ohealth/api/v1/isolation/noticebooktemplate/noticebook', params)

// 核查登记
export const updateAndCheck = params => http.post('/ohealth/api/v1/isolation/reg/updateAndCheck', params)

// 免身份验证核查登记
export const updateRegDetail = params => http.post('/ohealth/api/v1/isolation/reg/updateRegDetail', params)

// 修改个人信息
export const updatePersonalInfo = params => http.post('/ohealth/api/v1/isolation/reg/updatePersonalInfo', params)

// 查询入住筛查信息
export const infoRegLiveScreen = params => http.get('/ohealth/api/v1/isolation/reg/infoRegLiveScreen', params)

// 告知书管理
export const noticeBookTemplate = params => http.get('/ohealth/api/v1/isolation/noticebooktemplate/list', params)

// 告知书管理修改
export const updateNoticeBook = params => http.put('/ohealth/api/v1/isolation/noticebooktemplate/update', params)

// 告知书管理删除
export const deleteNoticeBook = params => http.delete('/ohealth/api/v1/isolation/noticebooktemplate/delete', params)

// 查看告知书模板
export const findIsolationNotice = params => httpimg.get('/ohealth/api/v1/isolation/reg/findIsolationNotice', params)
