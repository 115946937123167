// 引入工具函数
import { http, loghtp } from './index'


/* 登陆 */
export const login = params => loghtp.post('/ohealth/api/admin/login', params)


// 根据角色id获取路由菜单
export const getResListByCode = params => http.get('/ohealth/api/v1/system/sysres/getResListByRoleId', params)

//根据角色id获取功能按钮权限
export const getButtonListByCode = params => http.get('/ohealth/api/v1/system/sysres/getButtonListByRoleId', params)
