import axios from 'axios';
import router from '../router/index';
import { MessageBox, Message, Loading } from 'element-ui';
import store from '@/store'
import {
    getToken
} from '@/utils/auth';

const serverIP = '' //上线
axios.defaults.baseURL = '/api';



// exteriorUrl 用于系统管理菜单下人员管理/机构管理
const exteriorUrl = "http://admin.eh.medrd.cn"; // 测试环境
// const exteriorUrl = "https://admin.ehospital.medrd.com";  // 生产环境

const http = axios.create({
    baseURL: serverIP
})

const whiteList = [
    '/ohealth/api/v1/isolation/scheduling/getByDateRange',
    '/ohealth/api/v1/system/sysuser/getUserListByPeopleType',
    '/ohealth/api/v1/system/sysorg/getBusinessOrgList',
    '/ohealth/api/v1/isolation/scheduling/addScheduling',
    '/ohealth/api/v1/epidemicControl/streetreg/batchIsolationByHome',
    '/ohealth/api/v1/epidemicControl/streetreg/batchIsolationByHotel',
]

// request拦截器
http.interceptors.request.use(config => {
    let userdoctor = JSON.parse(localStorage.getItem('userdoctor'))
    if (getToken()) {
        config.headers['jtoken'] = getToken() // 让每个请求都携带携带token
        config.headers['hospitalId'] = userdoctor.hospitalId // 让每个请求都携带携带token
        config.headers['clientType'] = 'ADMIN'
        config.headers['userType'] = "USER_DOCTOR";
        // 让每个请求都携带携带hospitalId
        if (!config.params) {
            config.params = {}
        }
        if (!config.data) {
            config.data = {}
        }

        // whiteList 中请求时不需要加 orgCode
        if (whiteList.includes(config.url)) {
            config.data['hospitalId'] = userdoctor.hospitalId
            config.params['hospitalId'] = userdoctor.hospitalId
            return config;
        }

        config.data['hospitalId'] = userdoctor.hospitalId
        config.data['orgCode'] = userdoctor.orgCode
        config.params['hospitalId'] = userdoctor.hospitalId
        config.params['orgCode'] = userdoctor.orgCode
    } else {
        router.push("/login");
    }
    return config
}, error => {
    Promise.reject(error)
})

http.interceptors.response.use(
    res => {
        if (res.data.code == 401) {
            // 退出登录并跳转到登录页面 MGA登录失效，请重新登录
            store.dispatch('LoginOut').then(() => {
                router.push('/login')
            })
        }
        if (res.data.code == 400 && res.data.msg == 'MGA登录失效，请重新登录') {
            // 退出登录并跳转到登录页面
            store.dispatch('LoginOut').then(() => {
                router.push('/login')
            })
        }
        if (res.data.code != 200) {
            Message({
                message: res.data.msg,
                type: 'error'
            })
        }
        return res
    },
    err => {
        Message({
            message: '服务器繁忙，请稍后再试',
            type: 'error'
        })
        return Promise.reject(err)
    }
)


// 图片
const httpimg = axios.create({
    baseURL: serverIP
    
})

// // 下载文件拦截器
// httpexsl.interceptors.request.use(config => {
//     let userdoctor = JSON.parse(localStorage.getItem('userdoctor'))
//     config.headers['jtoken'] = getToken() // 让每个请求都携带携带token
//     config.headers['hospitalId'] = userdoctor.hospitalId // 让每个请求都携带携带token
//     config.headers['clientType'] = 'ADMIN'
//     config.headers['userType'] = "USER_DOCTOR";
//     config.responseType = 'blob';

//     return config
// }, error => {
//     Promise.reject(error)
// })


// request拦截器
httpimg.interceptors.request.use(config => {
    // Loading.service();
    let userdoctor = JSON.parse(localStorage.getItem('userdoctor'))
    config.headers['jtoken'] = getToken() // 让每个请求都携带携带token
    config.headers['hospitalId'] = userdoctor.hospitalId // 让每个请求都携带携带token
    config.headers['clientType'] = 'ADMIN'
    config.headers['userType'] = "USER_DOCTOR";
    config.responseType = 'blob';

    return config
}, error => {
    Promise.reject(error)
})

httpimg.interceptors.response.use(
    res => {
        // Loading.close()
        // if (res.data.code != 200) {
        //   Message({
        //     message: res.data.msg,
        //     type: 'error'
        //   })
        // }
        if (res.data.code == 401) {
            store.dispatch('LoginOut').then(() => {
                router.push('/login')
            })
        } else {
            return res
        }
    },
    err => {
        Message({
            message: '服务器繁忙，请稍后再试',
            type: 'error'
        })
        return Promise.reject(err)
    }
)

const httpxu = axios.create({
    baseURL: serverIP,
  })

// request拦截器
httpxu.interceptors.request.use(config => {

    return config
}, error => {
    Promise.reject(error)
})

httpxu.interceptors.response.use(
    res => {
        // Loading.close()
        // if (res.data.code != 200) {
        //   Message({
        //     message: res.data.msg,
        //     type: 'error'
        //   })
        // }
        if (res.data.code == 401) {
            store.dispatch('LoginOut').then(() => {
                router.push('/login')
            })
        } else {
            return res
        }
    },
    err => {
        Message({
            message: '服务器繁忙，请稍后再试',
            type: 'error'
        })
        return Promise.reject(err)
    }
)
// 登陆
const loghtp = axios.create({
    baseURL: serverIP
})

// request拦截器
loghtp.interceptors.request.use(config => {
    config.headers['clientType'] = 'ADMIN'

    if (!config.params) {
        config.params = {}
    }
    if (!config.data) {
        config.data = {}
    }
    return config
}, error => {
    Promise.reject(error)
})

loghtp.interceptors.response.use(
    res => {
        return res
    },
    err => {
        Message({
            message: '服务器繁忙，请稍后再试',
            type: 'error'
        })
        return Promise.reject(err)
    }
)

export {
    http,
    httpimg,
    loghtp,
    exteriorUrl,
    httpxu
}