import store from "@/store";

const btnPermission = (value) => {
    return store.getters.permission.btnLists.contain(value, 'permission')
};

const commonFunction = {
    install(Vue) {
        Vue.prototype.$btnPermission = btnPermission;
    },
}

export default commonFunction