import router from './router/index'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import { getToken,removeToken } from '@/utils/auth';
import { getResListByCode, getButtonListByCode } from '@/api/login'
import Vue from 'vue';

const whiteList = ['/login', '/404', '/403', '/500' ] // 不重定向白名单

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
    next()
  } else if (getToken()) { // 判断是否有token
    if (store.getters.permission.roleList && store.getters.permission.roleList.length > 0) {
      if (to.meta.isShow) { // 判断是否有权限
        next()
      } else {
        next('/404')
      }
    } else {
      if(to.path == '/home' && store.getters.permission.roleNull){
        next()
      }else{
        setRouters(to, from, next)
      }
    }
  } else {
    next({
      path: '/login'
    }) // 否则全部重定向到登录页
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})

export function setRouters(to, from, next) {
  setBtnList()

  let params = {
    roleId: store.getters.roles.id
  };
  getResListByCode({params}).then(res => {
    if (res.data.code === '200') {
      store.dispatch('generateRoutes', res.data.data).then(() => { // 生成可访问的路由表
        router.addRoutes(store.getters.permission.addRouters) // 动态添加可访问路由表
        // hack方法 确保addRoutes已完成
        if(res.data.data.length == 0){//不再获取权限
          store.commit('SET_ROLE_NULL', true)
        }
        next({ ...to })
      })
    } else {
      removeToken()
      store.dispatch('setRoleList', [])
      // 全部重定向到登录页
      next({ path: '/login' })
    }
  })
}

export function setBtnList() {
  let params = {
    roleId: store.getters.roles.id
  };
  getButtonListByCode({params}).then(res => {
    if (res.data.code === '200') {
      let btnLists = res.data.data;
      store.dispatch('setBtnLists', btnLists)
    } else {
      store.dispatch('setBtnLists', [])
    }
  })
}



Vue.directive('btnPermission', {
  inserted: function (el, binding) {
    if (!store.getters.permission.btnLists.contain(binding.value, 'permission')) {
      el.parentNode.removeChild(el)
    }
  }
})
