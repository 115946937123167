import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import app from './modules/app'
import dict from './modules/dict'
import getters from './getters'
import permission from './modules/permission'
import createPersistedstate from 'vuex-persistedstate'
import defaultAddress from './modules/defaultAddress'

Vue.use(Vuex)
// Vue.use(createPersistedstate)

const store = new Vuex.Store({
  modules: {
    user,
    app,
    dict,
    permission,
    defaultAddress
  },
  // 配置插件
  plugins: [
      // 默认储存在localstorage
      createPersistedstate({
          // 本地储存名
          key: 'usermassage',
          // 指定模块
          paths: ['user']
      })
  ],
  getters
})

export default store