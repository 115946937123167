const dict = {
    state: {
        isCrossDay: [
            {
                key: '0',
                value: '今'
            },
            {
                key: '1',
                value: '明'
            }
        ],
        schedulingBusinessType: [
            {
                key: '1',
                value: '居家隔离'
            },
            {
                key: '2',
                value: '集中隔离'
            }, {
                key: '3',
                value: '转运调度'
            }
        ],
        schedulingPeriodType: [
            {
                key: '1',
                value: '业务班段'
            },
            {
                key: '2',
                value: '管理班段'
            }
           
        ],
        schedulingType: [
            {
                key: 'community',
                value: '社区'
            },
            {
                key: 'driver',
                value: '司机'
            },
            {
                key: 'flowSurvey',
                value: '流调转运'
            }
        ]
    }
}

export default dict