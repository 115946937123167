import {
  MessageBox,Loading
} from 'element-ui';
import JSZip from '@/utils/jszip'



export function messageBox(callbalk, type, message = "") {
  type = type ? type : "warning";
  message = message ? message : "此操作将永久删除这条数据, 是否继续?";

  MessageBox.confirm(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: type
  }).then(() => {
    callbalk && callbalk();
  }).catch(() => {
    console.log("取消删除")
  });
}

// 获取可视区域宽高
export function getViewport() {
  if (window.innerWidth) {
    return {
      w: window.innerWidth,
      h: window.innerHeight
    };
  } else {
    if (document.compatMode === "BackCompat") {
      return {
        w: document.body.clientWidth,
        h: document.body.clientHeight
      };
    } else {
      return {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight
      };
    }
  }
}

// 生成随机字符串
export function randomString() {
  let str = null;
  var arr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  // 97~122  Unicode编码
  for (var i = 97; i < 122; i++) {
    if (arr.length < 32) {
      arr.push(String.fromCharCode(i));
    }
  }
  arr.sort(function () {
    return Math.random() - 0.5;
  })
  str = arr.join('')
  return str;
}

// 导出压缩文件
export function importZipFile(data, fileName) {

  //创建压缩文件并下载
  // var zip = new JSZip();
  // zip.file(fileName + '.zip', data);
  // 生成zip文件并下载
  // zip.generateAsync({
  //   type: 'blob'
  // }).then(function (content) {
  //   // 下载的文件名
  //   // var filename = fileName + '.zip';
  //   var filename = fileName;
  //   // 创建下载链接
  //   var el = document.createElement('a');
  //   el.download = filename;
  //   el.style.display = 'none';
  //   console.log(66666666, content);
  //   el.href = URL.createObjectURL(content);
  //   document.body.appendChild(el);
  //   el.click();
  //   document.body.removeChild(el);
  // });

  // 直接下载blob（压缩）文件
  var filename = fileName + '.zip';
  // 创建下载链接
  var el = document.createElement('a');
  el.download = filename;
  el.style.display = 'none';
  el.href = URL.createObjectURL(data);
  document.body.appendChild(el);
  el.click();
}

// 直接下载url文件
export function importUrlFile(data, fileName, suffix) {
    Loading.service();
// console.log( ' Loading',Loading);
  var filename = suffix ? (fileName + '.' + suffix) : fileName;
  // 创建下载链接
  var el = document.createElement('a');
  // console.log(filename);
  el.download = filename;
  // el.setAttribute("download", filename);
  el.style.display = 'none';
  el.href = data;
  document.body.appendChild(el);
  el.click();
}

// 导出文件
export function importFile(data, fileName) {
  const url = window.URL.createObjectURL(new Blob([data], {
    type: 'application/x-download'
  }));
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName + '.xlsx')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * 下载导出文件
 * @param blob         返回数据的blob对象或链接
 * @param tagFileName  下载后文件名标记
 * @param fileType     文件类 word(docx) excel(xlsx) ppt等
 */
export function downloadExportFile(blob, tagFileName = '', fileType = '') {
  let downloadElement = document.createElement('a');
  let href
  // console.log("downloadExportFile -> blob", blob)
  if (typeof blob == 'string') {
    href = blob;
    downloadElement.target = '_blank';
  } else {
    href = window.URL.createObjectURL(blob); //创建下载的链接
  }
  downloadElement.href = href;
  downloadElement.download = tagFileName + '.' + fileType; //下载后文件名
  document.body.appendChild(downloadElement);
  // console.log(22222222222, typeof blob, downloadElement);
  downloadElement.click(); //点击下载
  document.body.removeChild(downloadElement); //下载完成移除元素
  if (typeof blob != 'string') {
    window.URL.revokeObjectURL(href); //释放掉blob对象
  }
}



// 直接下载图片
export function downloadIamge(imgSrc, name) {
  //下载图片地址和图片名
  var image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = function () {
    var canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, image.width, image.height);
    var _dataURL = canvas.toDataURL('image/png'); //得到图片的base64编码数据

    var blob_ = dataURLtoBlob(_dataURL); // 用到Blob是因为图片文件过大时，在一部风浏览器上会下载失败，而Blob就不会

    var url = {
      name: name || "图片.png", // 图片名称不需要加.png后缀名
      src: blob_
    };

    if (window.navigator.msSaveOrOpenBlob) { // if browser is IE
      navigator.msSaveBlob(url.src, url.name); //filename文件名包括扩展名，下载路径为浏览器默认路径
    } else {
      var link = document.createElement("a");
      link.setAttribute("href", window.URL.createObjectURL(url.src));
      link.setAttribute("download", url.name + '.png');
      document.body.appendChild(link);
      link.click();
    }
  };
  image.src = imgSrc;

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
      type: mime
    });
  }
}

// 时间转换 年 月 日 时 分 秒
export function formatDate(dateStr, pattern = '') {
  if (dateStr == null || dateStr == "") {
    return;
  }
  var dt = new Date(dateStr)
  // yyyy-mm-dd
  var y = dt.getFullYear()
  var m = (dt.getMonth() + 1).toString().padStart(2, '0')
  var d = dt.getDate().toString().padStart(2, '0')

  if (pattern.toLowerCase() === 'yyyy-mm-dd') {
    return `${y}-${m}-${d}`
  } else {
    var hh = dt.getHours()
    var mm = dt.getMinutes().toString().padStart(2, '0')
    var ss = dt.getSeconds().toString().padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  }
}

// 身份证信息转换
export function getIdNumberInfo(idNumber) {
  //定义地区数组
  var provinces = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外"
  };
  var province = provinces[parseInt(idNumber.substr(0, 2))];
  // //二、出生日期
  // var birthday = idNumber.substring(6, 10) + "-" + idNumber.substring(10, 12) + "-" + idNumber.substring(12, 14);
  //三、计算年龄
  var currDate = new Date();
  var month = currDate.getMonth() + 1;
  var date = currDate.getDate();
  var age = currDate.getFullYear() - idNumber.substring(6, 10) - 1;
  var sex = null;
  //判断年龄
  if (idNumber.substring(10, 12) < month || (idNumber.substring(10, 12) == month && idNumber.substring(12, 14) <= date)) {
    age++;
  }
  //四、获取性别
  if (parseInt(idNumber.substr(16, 1)) % 2 == 1) {
    sex = "0"; // 男
  } else {
    sex = "1"; // 女
  }
  let data = {
    province: province,
    age: age,
    sex: sex
  }
  return data;
}

// 打印条码
export function printBarcode(params) {
  let {
    barCode,
    isolationName,
    patientName,
    sampleTime,
    roomNo
  } = params;
  let textArr = [barCode, isolationName, patientName, sampleTime, roomNo];

  let data = [{
    EntityTypeId: "123",
    Text: textArr,
    Code: [barCode]
  }];
  let option = {
    modal: false,
    noView: "TB60_200",
    printNum: 1
  };

  window.wewin.LabelPrint(data, option, res => {
    //打印回调函数
    if (res.status === 1) {
      this.$message.success("打印成功");
    } else {
      this.$message.error(res.error);
    }
  });
}


// 批量打印条码:params 数组形式，可以打印多个条码
export function batchPrintBarcode(params) {
  let datas = [];
  params.forEach(param =>{
    let {
      barCode,
      isolationName,
      patientName,
      sampleTime,
      roomNo
    } = param;
    let textArr = [barCode, isolationName, patientName, sampleTime, roomNo];
    let data = {
      EntityTypeId: "123",
      Text: textArr,
      Code: [barCode]
    };
    datas.push(data);
  })

  let option = {
    modal: false,
    noView: "TB60_200",
    printNum: 1
  };

  window.wewin.LabelPrint(datas, option, res => {
    //打印回调函数
    if (res.status === 1) {
      this.$message.success("打印成功");
    } else {
      this.$message.error(res.error);
    }
  });
}

// 打印抗体检测标签
export function printAntibodyBarcode(params) {
  let {
    barCode,
    titleName,
    patientName,
    orgName,
    sampleTime,
    doctorName
  } = params;
  let textArr = [barCode, titleName, patientName, orgName, sampleTime, doctorName];

  let data = [{
    EntityTypeId: "printAntibodyBarcode",
    Text: textArr,
    Code: [barCode]
  }];
  let option = {
    modal: false,
    noView: "TB60_200",
    printNum: 1
  };

  window.wewin.LabelPrint(data, option, res => {
    //打印回调函数
    if (res.status === 1) {
      this.$message.success("打印成功");
    } else {
      this.$message.error(res.error);
    }
  });
}
// 防抖
export function _debounce(fn, delay) {
 
  var delay = delay || 200;
  var timer;
  return function () {
      var th = this;
      var args = arguments;
      if (timer) {
          clearTimeout(timer);
      }
      timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
      }, delay);
  };
}
// 节流
export function _throttle(fn, interval) {
  var last;
  var timer;
  var interval = interval || 200;
  return function () {
      var th = this;
      var args = arguments;
      var now = +new Date();
      if (last && now - last < interval) {
          clearTimeout(timer);
          timer = setTimeout(function () {
              last = now;
              fn.apply(th, args);
          }, interval);
      } else {
          last = now;
          fn.apply(th, args);
      }
  }
}

/**数组根据数组对象中的某个属性值进行排序的方法
 * 使用例子：newArray.sort(sortBy('number',false)) //表示根据number属性降序排列;若第二个参数不传递，默认表示升序排序
 * @param attr 排序的属性 如number属性
 * @param rev true表示升序排列，false降序排序
 * */
export function sortBy(attr, rev) {
  //第二个参数没有传递 默认升序排列
  if (rev === undefined) {
    rev = 1;
  } else {
    rev = (rev) ? 1 : -1;
  }

  return function (a, b) {
    a = a[attr];
    b = b[attr];
    if (a < b) {
      return rev * -1;
    }
    if (a > b) {
      return rev * 1;
    }
    return 0;
  }
}