import { configKey } from '../../api/SystemManagement/isolationpointmanagement';

export default {
    state:{
        addressFourCode:"",
        addressFourDetail:"",
        addressThreeCode:"",
        addressThreeDetail:""
    },
    mutations: {
        SET_FOUR_CODE: (state, payload) => {
            state.addressFourCode = payload
        },
        SET_FOUR_DETAIL: (state, payload) => {
            state.addressFourDetail = payload
        },
        SET_THREE_CODE: (state, payload) => {
            window.localStorage.setItem('addressThreeCode',JSON.stringify(payload))
            state.addressThreeCode = payload
        },
        SET_THREE_DETAIL: (state, payload) => {
            window.localStorage.setItem('addressThreeDetail',JSON.stringify(payload))
            state.addressThreeDetail = payload
        }
    },
    actions: {
        async getDefaultAllAddress({ commit }) {
            const configkeys = ['default_province_town_code','default_province_town_name','default_province_urban_code','default_province_urban_name']
            const [
                fourAddrssCodeRes,
                fourAddrssRes,
                threeAddrssCodeRes,
                threeAddrssRes,
            ] = await Promise.all(configkeys.map(o => configKey(o)));
            commit('SET_FOUR_CODE', fourAddrssCodeRes.data.data);
            commit('SET_FOUR_DETAIL', fourAddrssRes.data.data);
            commit('SET_THREE_CODE', threeAddrssCodeRes.data.data);
            commit('SET_THREE_DETAIL', threeAddrssRes.data.data);
        }
    }
}