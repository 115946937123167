const app = {
    state: {
        client: {
            height: 0,/* 页面高度 */
            width: 0 /* 页面宽度 */
        },
        size: 'medium',
        loading: false,
        hospitalId: '',
    },
    mutations: {
        SET_CLIENT_HEIGHT: (state, height) => {
            state.client.height = height
        },
        SET_CLIENT_WIDTH: (state, width) => {
            state.client.width = width
        },
        SET_SIZE: (state, size) => {
            state.size = size
        },
        SET_LOADING: (state, loading) => {
            state.loading = loading
        },
        SET_HOSPITAL_ID: (state, hospitalId) => {
            state.hospitalId = hospitalId
        }
    },
    actions: {
        setClientHeight({ commit }, height) {
            commit('SET_CLIENT_HEIGHT', height)
        },
        setClientWidth({ commit }, width) {
            commit('SET_CLIENT_WIDTH', width)
        },
        setSize({ commit }, size) {
            commit('SET_SIZE', size)
        },
        setLoading({ commit }, loading) {
            commit('SET_LOADING', loading)
        },
        setHospitalId({ commit }, hospitalId) {
            commit('SET_HOSPITAL_ID', hospitalId)
        }
    }
}

export default app