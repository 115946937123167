import { constantRouterMap, asyncRouterMap } from '@/router'
import store from '@/store'
import {sortBy} from '@/utils/utils'


/**
 * 过滤过滤 admins 角色
 * @param routerMap
 * @param roleList
 */
function filterAsyncRouter (routerMap, roleList) {
  filterRouter(routerMap, roleList)
  return routerMap
}
/**componentPath
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routerMap
 * @param roleList
 * @param parentPath
 */
function filterRouter (routerMap, roleList, parentPath) {
  routerMap.forEach(route => {
    if (store.getters.roles.code === 'SYS_ADMIN') {
      route.meta.isShow = true
    } else {
      hasPermission(route, roleList)
    }
    route.selectedPath = parentPath ? parentPath + '/' + route.path : route.path
    if (route.children) {
      filterRouter(route.children, roleList, route.selectedPath)
    }
  })
  routerMap.sort(sortBy('seq', true))
}

/**
 * 动态权限判断
 * @param route   菜单选择
 * @param roleList
 */
function hasPermission (route, roleList) {
  for (let i = 0; i < roleList.length; i++) {
    if (route.meta.permission === roleList[i].permission + '') {
      route.meta.isShow = true
      route.meta.icon = roleList[i].iconCls
      route.seq = roleList[i].seq
      route.name = roleList[i].name
      return
    } else {
      route.meta.isShow = false
    }
  }
}

const permission = {
  state: {
    routers: constantRouterMap,
    roleList: [],
    btnLists: [],
    addRouters: [],
    roleNull:false,//该角色没有设置权限
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_ROLE_LIST: (state, roleList) => {
      state.roleList = roleList
    },
    SET_Btn_LIST: (state, btnLists) => {
      state.btnLists = btnLists
    },
    SET_ROLE_NULL: (state, val) => {
      state.roleNull = val
    },
  },
  actions: {
    generateRoutes ({ commit }, roles) {
      return new Promise(resolve => {
        if (store.getters.roles.code === 'SYS_ADMIN') {
          commit('SET_ROLE_LIST', [{},{}])
        } else {
          commit('SET_ROLE_LIST', roles)
        }
        commit('SET_ROUTERS', filterAsyncRouter(asyncRouterMap, roles))
        resolve()
      })
    },
    setRoleList ({ commit }, roles) {
      commit('SET_ROLE_LIST', roles)
    },
    setBtnLists ({ commit }, roles) {
      commit('SET_Btn_LIST', roles)
    }
  }
}

export default permission
