
/**
 * 数组删除指定元素
 * @param val
 */
Array.prototype.remove = function (val) {
    let index = this.indexOf(val)
    if (index > -1) {
        this.removeByIndex(index)
    }
}
/**
 * 数组删除指定元素
 * @param index
 */
Array.prototype.removeByIndex = function (index) {
    this.splice(index, 1)
}

/**
 * 数组排序（根据某一个字段排序或者直接排序）
 * @param options   key(时间)    desc: 是否倒序 （ture/false）
 */
Array.prototype.shellSort = function (options) {
    let {key, desc} = options || {}
    let N = this.length;
    let h = 1;
    while (h < N/3) {
        h = 3 * h + 1;
    }
    while (h >= 1) {
        for (let i = h; i < N; i ++) {
            if (key) {
                for (let j = i; j >= h && (desc? this[j][key] > this[j-h][key] : this[j][key] < this[j-h][key]); j -= h) {
                    let temp = this[j];
                    this[j] = this[j - h];
                    this[j - h] = temp;
                }
            } else {
                for (let j = i; j >= h && (desc? this[j] > this[j-h] : this[j] < this[j-h]); j -= h) {
                    let temp = this[j];
                    this[j] = this[j - h];
                    this[j - h] = temp;
                }
            }
        }
        h = (h-1)/3;
    }
}

/**
 * 数组去重 (key为元素中的某一个属性，默认对比整个对象)
 * @param key
 * @returns {*|*[]}
 */
Array.prototype.deDuplication = function (key) {
    return this.reduce((pre,cur)=>{
        return pre.contain(cur, key) ? pre : pre.concat(cur)
    },[])
}

/**
 * 判断元素中是否包含此元素 (key为元素中的某一个属性，默认对比整个对象)
 * @param val
 * @param key1
 * @param key2
 * @returns {boolean}
 */
Array.prototype.contain = function (val, key1, key2) {
    if (key1) {
        if (key2) {
            for (let i = 0; i < this.length; i++) {
                if (typeof val === 'object') {
                    if (this[i][key1][key2] === val[key1][key2]) {
                        return true
                    }
                } else {
                    if (this[i][key1][key2] === val) {
                        return true
                    }
                }
            }
        } else {
            for (let i = 0; i < this.length; i++) {
                if (typeof val === 'object') {
                    if (this[i][key1] === val[key1]) {
                        return true
                    }
                } else {
                    if (this[i][key1] === val) {
                        return true
                    }
                }
            }
        }
    } else {
        return this.indexOf(val) > -1
    }
}

/**
 * 数组查找指定元素
 * @param val
 * @param key
 * @returns {*}
 */
Array.prototype.getByKey = function (key, val) {
    for (let i = 0; i < this.length; i++) {
        if (typeof val === 'object') {
            if (this[i][key] === val[key]) {
                return this[i]
            }
        } else {
            if (this[i][key] === val) {
                return this[i]
            }
        }
    }
}


/**
 * 清除字符串所有空格
 * @return {string}
 */
String.prototype.ClearSpaces = function () {
    return this.replace(/\s+/g, "");
}

/**
 * 清除字符串所有换行
 * @return {string}
 */
String.prototype.ClearBr = function () {
    let str = this.replace(/<\/?.+?>/g,"");
    str.replace(/[\r\n]/g, "");
    return str;
}

/**
 * 清除字符串所有空格和回车
 * @return {string}
 */
String.prototype.ClearSpacesAndBr = function () {
    let str = this.ClearBr();
    str = this.ClearSpaces()
    return str;
}

/**
 * 清除字符串左边空格
 * @return {string}
 */
String.prototype.ClearLeftSpaces = function () {
    return this.replace(/^\s*/g,"");
}

/**
 * 清除字符串右边空格
 * @return {string}
 */
String.prototype.ClearRightSpaces = function () {
    return this.replace(/\s*$/g,"");
}

/**
 * 清除字符串两边空格
 * @return {string}
 */
String.prototype.ClearBothSidesSpaces = function () {
    return this.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 清除字符串中间空格
 * @return {string}
 */
String.prototype.ClearInsideSpaces = function () {
    return this.replace(/\s/g,'');
}
